.PageBar {
  background-color: var(--bg);
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.pagebar-logo-icon {
  margin: 10px;
}

.pagebar-title {
  font-size: 14pt;
  line-height: 16pt;
  font-style: normal;
  text-align: center;
  font-weight: bold;
  margin: 10px;
  width: 230px;
  align-self: center;
}

.css-1lk70dc-MuiPaper-root-MuiAppBar-root {
  background-color: var(--bg);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  box-shadow: none !important;
}

.pagebar-icon .css-tzssek-MuiSvgIcon-root {
  background-color: #66829a;
  border-radius: 15%;
  color: white;
}

.css-1t29gy6-MuiToolbar-root {
  display: flex !important;
  justify-content: space-between !important;
}
