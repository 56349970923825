.References {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: white;
}

.ref-item {
  background-color: #efefef;
  margin-top: 2px;
  color: black;
  display: inherit;
  align-items: center;
  justify-content: space-between;
}

.ref-item-title {
  font-size: 16pt;
  line-height: 19pt;
  text-align: left;
  margin: 10px;
}

.ref-item-icon {
  margin-right: 25px;
}

.ref-item-icon .css-i4bv87-MuiSvgIcon-root {
  width: 10px;
  height: 20px;
}

/* CSSTransition classes  */
.ref-primary-enter {
  position: absolute;
  margin-right: 15px;
  opacity: 0;
}
.ref-primary-enter-active {
  position: absolute;
  margin-right: 15px;
  opacity: 1;
  transition: all 250ms ease;
}
.ref-primary-exit {
  opacity: 1;
}
.ref-primary-exit-active {
  opacity: 0;
  transition: all 250ms ease;
}

.ref-secondary-enter {
  opacity: 0;
  position: absolute;
  margin-right: 15px;
}
.ref-secondary-enter-active {
  opacity: 1;
  position: absolute;
  margin-right: 15px;
  transition: all 250ms ease;
}
.ref-secondary-exit {
  opacity: 1;
}
.ref-secondary-exit-active {
  opacity: 0;
  transition: all 250ms ease;
}
