.NavBar {
  background-color: var(--bg);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-radius: 0;
  height: 74px;
}

.navbar-container {
  display: flex;
  color: white;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin: 0 auto;
  max-width: 1200px;
}

.navbar-logo-icon {
  margin: 10px;
}

.navbar-title {
  font-size: 14pt;
  line-height: 16pt;
  font-style: normal;
  text-align: left;
  margin: 10px;
  width: 230px;
  align-self: center;
}

.navbar-icon .css-tzssek-MuiSvgIcon-root {
  background-color: #66829a;
  border-radius: 15%;
  color: white;
}

.css-hip9hq-MuiPaper-root-MuiAppBar-root {
  background-color: var(--bg);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: none !important;
}

.css-1t29gy6-MuiToolbar-root {
  display: flex !important;
  justify-content: space-between !important;
}

.navbar-link-text {
  font-weight: 600;
  font-size: 14px;
  margin-left: 0.5rem;
  align-self: center;
}

.dropdown {
  position: absolute;
  top: 60px;
  width: 450px;
  right: 0;
  background-color: var(--bg);
  border: var(--border);
  border-radius: var(--border-radius);
  padding: 5px;
  padding-top: 15px;
  transition: height var(--speed) ease;
  z-index: 1000;
}

.dropdown::after {
  content: "";
  top: -20px;
  position: absolute;
  width: 20px;
  height: 20px;
  right: 10%;
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: var(--bg);
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}

/* tooltip .bottom i::after {
    content:'';

    position:absolute;
    width:12px;
    height:12px;
    left:50%;
    transform:translate(-50%,50%) rotate(45deg);
    background-color:#EEEEEE;
    box-shadow:0 1px 8px rgba(0,0,0,0.5);
} */
