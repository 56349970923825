.App {
  text-align: center;
  background-color: white;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.fade-enter {
  opacity: 0;
  transform: translate(40px, 0px);
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translate(0, 0);

  transition: opacity 250ms ease-out, transform 300ms ease;
}
.fade-exit {
  opacity: 1;
  transform: translate(0, 0);
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transform: translate(0, 30px);
  /* transition: opacity 50ms ease-out, transform 30ms ease; */
}

.content {
  flex: 1;
  max-height: calc(100vh - 56px);
}

.calc {
  background-color: #efefef;
}

/* CSSTransition classes  */
.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}
.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu-primary-exit {
  position: absolute;
}
.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all var(--speed) ease;
}

.menu-secondary-enter {
  transform: translateX(110%);
}
.menu-secondary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu-secondary-exit {
}
.menu-secondary-exit-active {
  transform: translateX(110%);
  transition: all var(--speed) ease;
}
