.link-item {
    height: 96px;
    background-color: #efefef;
    margin-top: 5px;
    color:black;
    display: inherit;
    align-items: center;
    justify-content: space-between;
}

.Link {
    margin-top: 10px;
    display: flex;
    flex: 1;
    flex-direction: column;
    background: white;
    padding: 5px;
    border-radius: 10px;
}

.link-item-title {
    font-size: 16pt;
    line-height: 19pt;
    font-family: 'Helvetica Neue';
    font-weight: bold;
    text-align: left;
    margin: 10px;
}

.arrow-icon {
    margin-right: 30px;
    color: #EBB54C;
}