table {
  border-collapse: collapse;
  table-layout: fixed;
}
table td {
  border: 1px solid black;
  padding: 1rem 0.5rem;
}
table tr:first-child td {
  border-top: 0;
}
table tr td:first-child {
  border-left: 0;
}
table tr td:last-child {
  border-right: 0;
}

.slope-shield-selector {
  background: var(--bg);
  color: white;
  padding: 0.5rem;
}

.excavate-below {
  background-color: #d8d8d8;
  padding: 0.5rem;
}

.user-input {
  background-color: #f8f8f8;
}

input {
  box-shadow: 0 4px 0 #00000046;
  border-radius: 5px;
  border: 1px solid #9d9d9d;
  background-color: #ededed;
  padding: 0.75rem 1rem;
  width: 60px;
  text-align: center;
  font: normal bold 20px Helvetica Neue, serif;
}

input:focus-visible {
  outline: #00000046 auto 1px !important;
}

.input-title {
  text-align: left;
}

.input-value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
}

.value-label {
  font: normal bold 20px Helvetica Neue, serif;
  padding: 0.75rem;
  width: 60px;
}

.unit-label {
  font: normal bold 12px Helvetica Neue, serif;
  padding-right: 0.5rem;
}

.input-unit-label {
  font: normal bold 12px Helvetica Neue, serif;
}

.td-no-input {
  padding: 0rem;
}

.fade1-enter {
  opacity: 0;
  border-color: #fff;
}
.fade1-enter.fade1-enter-active {
  opacity: 1;
  transition: opacity 250ms ease;
}
.fade1-enter.fade1-enter-active td {
  border-color: black;
  transition: border 300ms ease;
}

.fade1-exit {
  opacity: 1;
}
.fade1-exit.fade1-exit-active {
  opacity: 0;
  transition: opacity 250ms ease-out;
}
.fade1-exit.fade1-exit-active td {
  border-color: #fff;
  transition: border 300ms ease;
}

#growl {
  color: red;
  font-size: smaller;
  font-style: italic;
}